import React from 'react'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import HeroPlanilhaDRE from './sections/hero-planilha-dre/_index'
import GerenciarFinancas from './sections/gerenciar-financas/_index'
import PorqueAbrirUmaConta from './sections/porque-abrir-uma-conta/_index'
import { Wrapper } from './style'

const PlanilhaDRE = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <HeroPlanilhaDRE />
        <GerenciarFinancas />
        <PorqueAbrirUmaConta />
      </Layout>
    </Wrapper>
  )
}

export default PlanilhaDRE
